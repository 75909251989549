import { useEffect } from "react";
import Cookies from "js-cookie";
import { isMobile, isIOS } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import Navbar from '../component/Navbar.js';
import Footer from '../component/Footer.js';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function PWA() {
  const navigate = useNavigate();
  
  const { t } = useTranslation();
  
  useEffect(() => {
    if (!isMobile) {
      navigate("/");
      return;
    }
    Cookies.set("SZ-pwa", "true", { expires: 7 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const video = isIOS ? 'ios' : 'android';
  
  return (
    <>
      <Helmet>
        <title>Instalar app | SleepZzone</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <Navbar isInnerPage={true} />
      <section className="main-gallery-page pb-8 lg:pb-32">
        <div className="wrapper mx-auto px-5 lg:px-0">
          <div className="gallery-inner pt-32 lg:pt-16">
            <h3 className="text-white text-center text-2xl lg:text-4xl leading-snug font-black mb-5">
              {t('PWA.titulo')}
            </h3>
            <video height={1080} width={1920} className="w-full m-auto max-w-[600px]" autoPlay controls>
              <source src={`/pwa/videos/${video}.mp4`} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
      <Footer isDarkPage={true} />
    </>
  );
}
