import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../component/Navbar.js";
import Message from "../../component/HomeComponent/Message.js";
import Banner from "../../component/HomeComponent/Banner.js";
import Solutions from "../../component/HomeComponent/Solutions.js";
import Tabssec from "../../component/HomeComponent/Tabssec.js";
import Benefits from "../../component/HomeComponent/Benefits.js";
import Products from "../../component/HomeComponent/Products.js";
import Investment from "../../component/HomeComponent/Investment.js";
import Accesible from "../../component/HomeComponent/Accesible.js";
import Logosec from "../../component/HomeComponent/Logosec.js";
import Team from "../../component/HomeComponent/Team.js";
import Testimonials from "../../component/HomeComponent/Testimonials.js";
import HowToStart from "../../component/HomeComponent/HowToStart.js";
import Footer from "../../component/Footer.js";
import { Helmet } from "react-helmet";
import { useAuth } from "../../auth.js";
import { isMobile } from 'react-device-detect';
import Cookies from "js-cookie";

export default function Home() {
  const { signIn, userAuth0Context, isAuthenticatedContext, user } = useAuth();
  const navigate = useNavigate();

  const isUserVerify = () => (isAuthenticatedContext && userAuth0Context?.email_verified && userAuth0Context?.sub);

  useEffect(() => {
    if (
      isUserVerify() && !user
    ) {
      (async () => {
        await signIn(userAuth0Context.sub);
        navigate("/profile");
      })();
    }

  }, [isAuthenticatedContext]);

  let location = useLocation();
  useEffect(() => {
    if (
      isAuthenticatedContext &&
      !userAuth0Context?.email_verified &&
      location.state !== "verification"
    ) {
      navigate("/verification/register");
    }
  }, [isAuthenticatedContext]);

  const isHomePage = true;
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  
  useEffect(() => {
    const checkPWA = Cookies.get("SZ-pwa");
    if (isMobile && !checkPWA) {
      navigate("/pwa");
    }
  }, [isMobile]);
  
  return (
    <>
      <Helmet>
        <title>
          SleepZzone | Cuidamos de tu sueño para mejorar tu salud y bienestar{" "}
        </title>
        <meta
          name="description"
          content="Conviértete en una empresa saludable cuidando el sueño de tus empleados , aumentará tu productividad y el rendimiento global."
        />
        <meta
          name="keywords"
          content="Dormir, descansar, bienestar, descanso, sueño reparador, consejos para dormir, rendimiento, aprender a dormir"
        />
      </Helmet>
      <Message />
      <Navbar isHomePage={isHomePage} />
      <Banner />
      <Solutions />
      <Tabssec />
      <Benefits />
      <Products />
      <Investment />
      <Accesible />
      <Logosec />
      <Team />
      <Testimonials />
      <HowToStart />
      <Footer />
    </>
  );
}
