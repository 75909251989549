import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import PWA from './pages/PWA';
import Home from './pages/HomePage/Home';
import Login from './pages/Login';
import Gallery from './pages/Gallery/Gallery';
import Consultations from './pages/Consultations/Consultations';
import AdditionalInfo from './pages/Account/AdditionalInfo';
import ProfileSetting from './pages/Account/ProfileSetting';
import ROIpage from './pages/ROIpage';
import QuestionnairLayout from './pages/Questionnaire';
import Congratulation from './pages/Questionnaire/Congratulation';
import SleepBot from './pages/Sleepbot';
import News from './pages/News';
import ContactPage from './pages/ContactPage';
import ConsultationsPage from './pages/ConsultationsPage';
import ClinicHistory from './pages/Account/ClinicHistory';
import Valoration from './pages/Account/Valoration';
import BasicForm from './pages/Account/BasicForm';
import NotFound from './pages/NotFound';
import PrivacyPage from './pages/Privacy';
import ConditionsPage from './pages/ConditionsPage';
import VerificationPage from './pages/VerificationPage';
import CheckQuestionnaire from './CheckQuestionnaire';
import ResendPage from './pages/ResendPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import PreQuestionnair from './pages/Questionnaire/PreQuestionnair';

function App() {
  document.body.classList.add('scroll-smooth');

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="pwa" element={<PWA />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="contact/:type" element={<ContactPage />} />
        <Route path="consultations" element={<Consultations />} />
        <Route path="resend" element={<ResendPage />} />
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="profile" element={<Valoration />} />
        <Route path="conditions" element={<ConditionsPage />} />
        <Route path="additionalInfo" element={<AdditionalInfo />} />
        <Route path="PersonalInfo" element={<ProfileSetting />} />
        <Route path="clinicHistory" element={<ClinicHistory />} />
        <Route path="basicForm" element={<BasicForm />} />
        <Route path="roipage" element={<ROIpage />} />
        <Route
          path="questionnair"
          element={<CheckQuestionnaire></CheckQuestionnaire>}
        />
        <Route
          path="questionnair2"
          element={
            <QuestionnairLayout
              completed={false}
              completedAdvanced={false}
              completedSF36={false}
              index={0}
            />
          }
        />
        <Route path="prequestionnair" element={<PreQuestionnair />} />
        <Route path="congratulation" element={<Congratulation />} />
        <Route path="sleepbot" element={<SleepBot />} />
        <Route path="knowus" element={<News />} />
        <Route path="verification/:type" element={<VerificationPage />} />
        <Route path="consultationspage" element={<ConsultationsPage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="login" element={<Login />} />        
        <Route path="politica-de-privacidad" element={<PrivacyPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
