import { useTranslation } from "react-i18next";

export default function Team() {
  const { t } = useTranslation();
  return (
    <>
      <section className="Team-sec py-8 lg:py-16">
        <div className="wrapper mx-auto px-5 ">
          <div className="Team-header lg:mb-12 text-center">
            <h2 className="font-black text-3xl lg:text-5xl mb-3">
              {t("Team.titulo")}
            </h2>
            <p class="text-base">{t("Team.subtitulo")}</p>
          </div>
          <div className="flex justify-center mt-5 mb-10 text-center">
            <img className="max-w-full" src="/images/team.jpeg" alt={t("Team.titulo")} />
          </div>
          <div className="text-center mt-4">
            <a
              href="/knowus"
              className="rounded-lg inline-block text-white mr-6 blue-btn py-3.5 px-6 font-black border-2"
            >
              {t("Team.boton")}
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
